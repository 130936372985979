export default function Contact() {
  return (
    <div id="contact" class="z-20 flex">
      <div class="py-8 lg:py-16 px-4 mx-auto max-w-screen-md z-20">
        <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-center text-white">
          Contact Me
        </h2>
        <p class="mb-8 lg:mb-16 font-light text-center text-gray-400 sm:text-xl">
          Got a technical challenge? Interested in the possibilities of
          collaborating with me? Want to grab a coffee? Let me know.
        </p>
        <form
          action="https://api.web3forms.com/submit"
          method="POST"
          class="space-y-8"
        >
          <input
            type="hidden"
            name="access_key"
            value="737278c6-fb21-4643-b1ac-9d238df28770"
          />
          <div>
            <label
              for="email"
              class="block mb-2 text-sm font-medium text-gray-400"
            >
              Your email
            </label>
            <input
              type="email"
              name="email"
              id="email"
              class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
              placeholder="your@email.com"
              required
            />
          </div>
          <div>
            <label
              for="subject"
              class="block mb-2 text-sm font-medium text-gray-400"
            >
              Subject
            </label>
            <input
              type="text"
              name="subject"
              id="subject"
              class="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
              placeholder="Let me know how we can help you"
              required
            />
          </div>
          <div class="sm:col-span-2">
            <label
              for="message"
              class="block mb-2 text-sm font-medium text-gray-400"
            >
              Your message
            </label>
            <textarea
              id="message"
              name="message"
              rows="6"
              class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
              placeholder="Leave a message. . ."
            ></textarea>
          </div>
          <button
            type="submit"
            class="py-3 px-5 text-sm font-medium text-center text-white rounded-lg bg-wotgdarkblue  sm:w-fit hover:bg-wotglightblue  focus:ring-4 focus:outline-none focus:ring-primary-300"
          >
            Send message
          </button>
        </form>
      </div>
    </div>
  );
}
