import { useState } from "react";

import logo from "../assets/wotg-logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import divider from "../assets/divider-bluewave.svg";
import "./Header.css";

export default function Header() {
  const [loaded, setLoaded] = useState(true);
  return (
    <>
      {loaded ? (
        <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-white flex flex-col items-center justify-center">
          Loading...
        </div>
      ) : null}

      <div
        id="home"
        className="flex w-full h-screen flex-col md:flex-row gap-5 items-center justify-center text-white relative"
      >
        <div className="md:w-3/6 md:p-3 max-w-sm opacity-60 z-40">
          <img
            className="relative"
            data-aos="flip-right"
            data-aos-duration="1500"
            data-aos-offset="200"
            src={logo}
            alt="logo"
            onLoad={() => setLoaded(false)}
          />
        </div>
        <div
          className="md:w-3/6"
          data-aos="fade-right"
          data-aos-duration="1000"
          data-aos-offset="100"
        >
          <div className="flex flex-col w-full mt-8">
            <h1 className="text-xl text-gray-400">Hi, I'm</h1>
            <h1 className="text-2xl font-bold">Sam Vruggink</h1>
            <p class="text-xl font-bold text-gray-300">
              Data, DevOps and Cloud Engineer
            </p>
            <p className="text-md font-light text-gray-400 ">
            At WotG, we deliver premium IT consultancy, tailored development solutions, and robust platforms, all centered around our clients needs. Our primary objective is to assist clients in maximizing their IT investments while avoiding consultancy lock-in. We excel in crafting bespoke, business-driven cloud technologies that are purpose-built to empower your organization.
            </p>
          </div>

          <ul
            href="https://www.linkedin.com/in/sam-vruggink-440448127/"
            className="flex mt-2 gap-4 items-center z-auto"
          >
            <li>
              <a
                href="https://www.linkedin.com/in/sam-vruggink-440448127/"
                rel="noreferrer"
                target="_blank"
              >
                <FontAwesomeIcon
                  href="https://www.linkedin.com/in/sam-vruggink-440448127/"
                  size="2xl"
                  icon={faLinkedinIn}
                ></FontAwesomeIcon>
              </a>
            </li>
          </ul>
        </div>
        <img
          src={divider}
          id="techstack"
          className="w-full md:h-2 absolute bottom-0"
          alt="hr"
        />
      </div>
    </>
  );
}
