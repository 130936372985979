import hr from "../assets/divider-bluewave.svg";

export default function Footer() {
  return (
    <div className="mt-4 bg-dark-200 rounded-md text-white px-8 py-4">
      <ul className="text-center">
        <li>
          <a href="#home" className="hover:underline">
            About
          </a>
        </li>
        <li>
          <a href="#techstack" className="hover:underline">
            Techstack
          </a>
        </li>
        <li>
          <a href="#services" className="hover:underline">
            Services
          </a>
        </li>
        <li>
          <a href="#certs" className="hover:underline">
            Certfications
          </a>
        </li>
        <li>
          <a href="#contact" className="hover:underline">
            Contact
          </a>
        </li>
      </ul>

      <img
        src={hr}
        className="mb-4 mt-4 w-full md:h-1 text-white bottom-0"
        alt="hr"
      />
      <p class="text-center text-xs text-gray-400">
        Wizards of the Gracht<br></br>
        Kvk: 73496847 <br></br>
        Belastingnummer: NL002380472B21 <br></br>
        Hoge Rijndijk 1 - 27, 3449HA Woerden
      </p>
    </div>
  );
}
