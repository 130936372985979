import "./Services.css";
import codeImage from "../assets/code.png";
import codeImage2 from "../assets/code-2.jpg";
import divider from "../assets/divider-bluewave.svg";

export default function Services() {
  return (
    <section>
      <div
        id="services"
        class="gap-16 items-center py-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:py-32 lg:px-2"
      >
        <div class="font-light text-gray-500 sm:text-lg dark:text-gray-400">
          <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-white">
            Services
          </h2>
          <p class="mb-4 text-gray-400">
            Wizards of the Gracht is specialized in designing, building, and
            auditing Data, IoT and streaming platforms using (mostly) Microsoft
            Azure technology. WotG has extensive experience in developing
            scalable, secure, and reliable solutions that meet the unique
            business requirements our clients.
          </p>
          <p class="mb-4 text-gray-400">
            We offer a range of services to help you get the most out of your
            investments, including:
          </p>

          <p class="mb-4 bold text-white">
            <strong>Consultancy</strong>
            <br />
            <p class="mb-4 text-gray-400">
              Consultancy services focus on designing, building and auditing
              data-driven solutions. Leveraging our expertise in Cloud
              technology to provide you with tailored guidance, development and
              recommendations that enable you to maximize the value of your
              investment.
            </p>{" "}
          </p>
          <p class="mb-4 bold text-white">
            <strong>Platforms</strong>
            <br />
            <p class="mb-4 text-gray-400">in active development</p>
          </p>
        </div>
        <div class="grid grid-cols-2 gap-4 mt-8">
          <img
            class="w-full rounded-lg"
            src={codeImage}
            alt="office content 1"
          />
          <img
            class="mt-4 w-full lg:mt-10 rounded-lg"
            src={codeImage2}
            alt="office content 2"
          />
        </div>
      </div>
      <img src={divider} className="w-full mt-8 md:h-3" />
    </section>
  );
}
