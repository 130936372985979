import logo from "../assets/logo.svg";

export default function Navbar() {
  return (
    <div class="container mx-auto">
      <div className="fixed z-50  bg-dark-500 w-full top-0 left-0 px-8 py-4 lg:px-20 xl:px-36">
        <div className="flex justify-between items-center text-white">
          <a href="/">
            <img src={logo} className="App-logo w-10 full" alt="logo" />
          </a>
          <ul className="hidden md:flex">
            <li className="p-4">
              <a href="#home" className="hover:underline">
                About
              </a>
            </li>
            <li className="p-4">
              <a href="#techstack" className="hover:underline">
                Techstack
              </a>
            </li>
            <li className="p-4">
              <a href="#services" className="hover:underline">
                Services
              </a>
            </li>
            <li className="p-4">
              <a href="#certs" className="hover:underline">
                Certfications
              </a>
            </li>
            <li className="p-4">
              <a href="#contact" className="hover:underline">
                Contact us
              </a>
            </li>
          </ul>

          <button
            onClick={() => (window.location = "mailto:sam.vruggink@wotg.dev")}
            rel="noreferrer"
            target="_blank"
            className="bg-wotgdarkblue rounded-full px-4 py-1 border border-wotglightblue border border-5"
          >
            Email me!
          </button>
        </div>
      </div>
    </div>
  );
}
