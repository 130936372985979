import divider from "../assets/divider-bluewave.svg";

const certifications = [
  {
    id: 1,
    validity: "03-2022 ⇥ 03-2024",
    href: "https://www.credly.com/badges/745d8b3f-b01e-405f-bc44-e948a6bcfd06",
    imageSrc:
      "https://images.credly.com/size/340x340/images/987adb7e-49be-4e24-b67e-55986bd3fe66/azure-solutions-architect-expert-600x600.png",
    imageAlt: "Microsoft Certified: Azure Solutions Architect Expert badge",
    badgeName: "Microsoft Certified: Azure Solutions Architect Expert",
  },
  {
    id: 1,
    validity: "06-2022 ⇥ 06-2023",
    href: "https://www.credly.com/badges/6df0ae5c-8f2c-4c7e-86ab-6a56d3cadccc",
    imageSrc:
      "https://images.credly.com/size/340x340/images/63316b60-f62d-4e51-aacc-c23cb850089c/azure-developer-associate-600x600.png",
    imageAlt: "Microsoft Certified: Azure Developer Associate badge",
    badgeName: "Microsoft Certified: Azure Developer Associate",
  },
  {
    id: 1,
    validity: "11-2021 ⇥ 11-2023",
    href: "https://www.credly.com/badges/6144ff66-2b82-4299-8f4c-350fdb065ddc",
    imageSrc:
      "https://images.credly.com/size/340x340/images/61542181-0e8d-496c-a17c-3d4bf590eda1/azure-data-engineer-associate-600x600.png",
    imageAlt: "Microsoft Certified: Azure Data Engineer Associate badge",
    badgeName: "Microsoft Certified: Azure Data Engineer Associate",
  },
  {
    id: 1,
    validity: "07-22 ⇥ 07-2023",
    href: "https://www.credly.com/badges/32de2169-076c-4904-9b43-b26238da6e41",
    imageSrc:
      "https://images.credly.com/size/340x340/images/c3ab66f8-5d59-4afa-a6c2-0ba30a1989ca/CERT-Expert-DevOps-Engineer-600x600.png",
    imageAlt: "Microsoft Certified: DevOps Engineer Expert badge",
    badgeName: "Microsoft Certified: DevOps Engineer Expert",
  },
  {
    id: 1,
    validity: "10-2020 ⇥ 10-2023",
    href: "https://www.credly.com/badges/16dd0380-7d6c-469d-b766-d25bef215ab8",
    imageSrc:
      "https://images.credly.com/size/340x340/images/2711b780-c3f1-4678-a9ae-f6c49c379189/specialty-azure-iot-developer-600x600.png",
    imageAlt: "Microsoft Certified: Azure IoT Developer Specialty badge",
    badgeName: "Microsoft Certified: Azure IoT Developer Specialty",
  },
  {
    id: 1,
    validity: "03-2022",
    href: "https://www.credly.com/badges/b22378c8-94ed-4d7f-8dc1-00dcb3349ef2",
    imageSrc:
      "https://images.credly.com/size/340x340/images/9d7dc4c0-5681-41fc-b96b-26e9157786d7/image.png",
    imageAlt:
      "AZ-305: Designing Microsoft Azure Infrastructure Solutions Badge",
    badgeName: "AZ-305: Designing Microsoft Azure Infrastructure Solutions",
  },
  {
    id: 1,
    validity: "07-2022",
    href: "https://www.credly.com/badges/2ac9e8e9-518e-465c-9641-b434280360d2",
    imageSrc:
      "https://images.credly.com/size/340x340/images/107e2eb6-f394-40eb-83d2-d8c9b7d34555/exam-az400-600x600.png",
    imageAlt:
      "AZ-400: Designing and Implementing Microsoft DevOps Solutions Badge",
    badgeName: "AZ-400: Designing and Implementing Microsoft DevOps Solutions",
  },
  {
    id: 1,
    validity: "03-2022",
    href: "https://www.credly.com/badges/6308deef-7d64-4abd-8ea3-c6fb510713ae",
    imageSrc:
      "https://images.credly.com/size/340x340/images/285339cc-675a-4b1a-bdd9-283868af2fc8/EXAM-Expert-AZ-303-600x600.png",
    imageAlt: "AZ-303: Microsoft Azure Architect Technologies Badge",
    badgeName: "AZ-303: Microsoft Azure Architect Technologies",
  },
  {
    id: 1,
    validity: "06-2021 ⇥ 02-2024",
    href: "https://www.credly.com/badges/3cb9a87a-416a-483a-964e-3e7151f58c9a",
    imageSrc:
      "https://images.credly.com/size/340x340/images/99289602-861e-4929-8277-773e63a2fa6f/image.png",
    imageAlt: "HashiCorp Certified: Terraform Associate 002 badge",
    badgeName: "HashiCorp Certified: Terraform Associate",
  },
  {
    id: 1,
    validity: "09-2022 ⇥ 09-2023",
    href: "https://credentials.databricks.com/c1b33798-dd0e-44a1-b485-16b38bbe74ab#gs.qzkg6b",
    imageSrc: "https://i.imgur.com/kymLzeL.png",
    imageAlt: "Databricks Lakehouse Fundamentals",
    badgeName: "Databricks Lakehouse Fundamentals",
  },
  {
    id: 1,
    validity: "03-2018",
    href: "https://www.linkedin.com/in/sam-vruggink-440448127/details/certifications/",
    imageSrc:
      "https://embed.exin.totalservices.io/sites/default/files/2018-11/EXIN_Badge_ModuleFoundation_InformationSM_ISO.png",
    imageAlt: "Information Security Foundation based on ISO IEC 27001 badge",
    badgeName: "Information Security Foundation based on ISO IEC 27001",
  },
  {
    id: 1,
    validity: "12-2018",
    href: "https://www.linkedin.com/in/sam-vruggink-440448127/details/certifications/",
    imageSrc:
      "https://pbs.twimg.com/profile_images/694998838961160193/G_OLKZWd_400x400.png",
    imageAlt: "Agile Foundation Certificate",
    badgeName: "Agile Foundation Certificate",
  },
  {
    id: 1,
    validity: "04-2016",
    href: "#",
    imageSrc: "https://www.anglia.nl/uploads/1/2/0/0/120044573/anglia-nl_7.jpg",
    imageAlt: "Anglia C1",
    badgeName: "Anglia C1",
  },
];

export default function Badges() {
  return (
    <div id="certs" className="bg-transparant">
      <div className="mx-auto max-w-2xl py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8">
        <h2 className="text-2xl font-bold text-white">
          View my acquired certifications
        </h2>
        <p className="font-light text-gray-400">including but not limited to</p>

        <div className="mt-6 grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8">
          {certifications.map((certificate) => (
            <div key={certificate.id} className="group relative">
              <div className="min-h-80 aspect-w-1 aspect-h-1 w-full overflow-hidden rounded-md bg-dark-200 group-hover:opacity-75 lg:aspect-none lg:h50">
                <img
                  src={certificate.imageSrc}
                  alt={certificate.imageAlt}
                  className="h-full w-full object-cover object-center lg:h-70 lg:w-70"
                />
              </div>
              <div className="mt-4 flex justify-between">
                <div>
                  <h3 className="text-sm text-gray-700">
                    <a href={certificate.href}>
                      <span aria-hidden="true" className="absolute inset-0" />
                      {certificate.validity}
                    </a>
                  </h3>
                  <p className="mt-1 text-sm text-gray-500">
                    {certificate.badgeName}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <img src={divider} className="w-full mt-8 md:h-3" />
    </div>
  );
}
