import "./App.css";
import { useEffect } from "react";
import Navbar from "./components/Navbar";
import Header from "./components/Header";
import Skills from "./components/Skills";
import Footer from "./components/Footer";
import AOS from "aos";
import "aos/dist/aos.css";
import Badges from "./components/Badges";
import Services from "./components/Services";
import Contact from "./components/Contact";
import Particles from "./components/ParticlesBg";

function App() {
  useEffect(() => {
    document.title = "Wizards of the Gracht";
    AOS.init();
  }, []);
  return (
    <div className="px-6 lg:px-20 xl:px-36 bg-dark-500">
      <Navbar />
      <Header />
      <Skills />
      <Services />
      <Badges />
      <Contact />
      <Footer />
      <Particles />
    </div>
  );
}

export default App;
