import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SkillCard from "./SkillCard.js";

import terraform from "../assets/skills/terraform.svg";
import bash from "../assets/skills/bash.svg";
import linux from "../assets/skills/linux.svg";
import python from "../assets/skills/python.svg";
import reactIcon from "../assets/skills/react.svg";
import tailwind from "../assets/skills/tailwind.svg";
import kubernetes from "../assets/skills/kubernetes.svg";
import ansible from "../assets/skills/ansible.svg";
import windows from "../assets/skills/windows.svg";
import react from "../assets/skills/react.svg";
import docker from "../assets/skills/docker.svg";
import git from "../assets/skills/git.svg";
import express from "../assets/skills/express.svg";
import gitlab from "../assets/skills/gitlab.svg";
import databricks from "../assets/skills/databricks.svg";
import azure from "../assets/skills/azure.svg";
import divider from "../assets/divider-bluewave.svg";
import azureDevops from "../assets/skills/azure-devops.svg";
import ad from "../assets/skills/ad.svg";
import dataplatform from "../assets/skills/dataplatform.svg";
import iot from "../assets/skills/iot.svg";
import stream from "../assets/skills/stream.svg";
import cicd from "../assets/skills/cicd.svg";
import nodejs from "../assets/skills/nodejs.svg";
import security from "../assets/skills/security.svg";

export default function Skills() {
  const settings = {
    dots: false,
    autoplay: true,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
  };

  return (
    <div id="" className="mt-4 text-white">
      <h1 className="text-2xl font-bold">Techstack</h1>
      <p className="font-light text-gray-400">including but not limited to</p>
      <div className="mt-4">
        <Slider {...settings}>
          <SkillCard name="Microsoft Azure" img={azure} />
          <SkillCard name="Python" img={python} />
          <SkillCard name="Terraform" img={terraform} />
          <SkillCard name="Databricks" img={databricks} />
          <SkillCard name="CD/CD" img={cicd} />
          <SkillCard name="Azure DevOps" img={azureDevops} />
          <SkillCard name="React" img={reactIcon} />
          <SkillCard name="Gitlab" img={gitlab} />
          <SkillCard name="AD" img={ad} />
          <SkillCard name="Docker" img={docker} />
          <SkillCard name="git" img={git} />
          <SkillCard name="Windows" img={windows} />
          <SkillCard name="Linux" img={linux} />
          <SkillCard name="Node.js" img={nodejs} />
          <SkillCard name="React" img={react} />
          <SkillCard name="Tailwind" img={tailwind} />
          <SkillCard name="Bash/Powershell" img={bash} />
        </Slider>
      </div>
      <img src={divider} className="w-full mt-8 md:h-3" alt="divider" />
    </div>
  );
}
